import * as JSPM from "jsprintmanager";
import Utility from "../../../shared/utility";
import breadcrumbComp from "../../common/breadcrumb-comp";
import EncryptUtility from "../../utility/js/encrypt-utility";
import commonAPIService from "../../common/js/common-api-service";
export default {
  data() {
    return {
      primaryCheck: EncryptUtility.localStorageDecrypt("primaryCheck"),
      secondaryCheck: EncryptUtility.localStorageDecrypt("secondaryCheck"),
      userId: EncryptUtility.localStorageDecrypt("userID"),
      secondaryName: EncryptUtility.localStorageDecrypt("secondaryName"),
      secondaryLabelSize: EncryptUtility.localStorageDecrypt("secondaryLabelSize"),
      secondaryPrinterType: EncryptUtility.localStorageDecrypt("secondaryPrinterType"),
      primaryName: EncryptUtility.localStorageDecrypt("primaryName"),
      primaryLabelSize: EncryptUtility.localStorageDecrypt("primaryLabelSize"),
      primaryPrinterType: EncryptUtility.localStorageDecrypt("primaryPrinterType"),
      printerId: EncryptUtility.localStorageDecrypt("printerID"),
      printerIP: EncryptUtility.localStorageDecrypt("printerIP"),
      favouriteList: [],
      showAdd: false,
      printers: [],
      actionList: [],
      disableSave: true,
      defaultCheck: EncryptUtility.localStorageDecrypt("defaultCheck"),
      defaultName: EncryptUtility.localStorageDecrypt("defaultName"),
      primaryLabelSizeData: [
        {
          prilabelname: "2X4",
          prilabelvalue: "2X4",
        },
        {
          prilabelname: "4X6",
          prilabelvalue: "4X6",
        },
        {
          prilabelname: "4X6.75 LEADING DOC TAB",
          prilabelvalue: "4X6.75 LEADING DOC TAB",
        },
        {
          prilabelname: "4X6.75 TRAILING DOC TAB",
          prilabelvalue: "4X6.75 TRAILING DOC TAB",
        },
        {
          prilabelname: "4X8",
          prilabelvalue: "4X8",
        },
        {
          prilabelname: "4X9 LEADING DOC TAB",
          prilabelvalue: "4X9 LEADING DOC TAB",
        },
        {
          prilabelname: "4X9 TRAILING DOC TAB",
          prilabelvalue: "4X9 TRAILING DOC TAB",
        },
      ],
      primaryPrinterTypeData: [
        {
          priprintername: "ZEBRA",
          priprinterval: "ZEBRA",
        },
        {
          priprintername: "DATAMAX",
          priprinterval: "DATAMAX",
        },
      ],
      secondaryLabelSizeData: [
        {
          seclabelname: "1X3",
          seclabelvalue: "1X3",
        },
        {
          seclabelname: "2X4",
          seclabelvalue: "2X4",
        },
        {
          seclabelname: "4X6",
          seclabelvalue: "4X6",
        },
      ],
      secondaryPrinterTypeData: [
        {
          secprintername: "ZEBRA",
          secprinterval: "ZEBRA",
        },
        {
          secprintername: "DATAMAX",
          secprinterval: "DATAMAX",
        },
      ],
    };
  },
  mounted() {
    this.onInit();
  },
  created() {},
  methods: {
    //This is used for resetting the form
    resetFunction() {
      this.primaryCheck = false;
      this.primaryName = "";
      this.primaryLabelSize = "";
      this.primaryPrinterType = "";
      this.secondaryCheck = false;
      this.secondaryName = "";
      this.secondaryLabelSize = "";
      this.secondaryPrinterType = "";
      this.disableSave = true;
      this.printerId = 0;
      this.defaultCheck = false;
      this.defaultName = "";
      this.$refs.primaryForm.resetValidation();
      this.$refs.secondaryForm.resetValidation();
      this.$refs.defaultForm.resetValidation();
    },
    //If Primary checkbox is checked
    isPrimary() {
      if (!this.primaryCheck) {
        this.primaryCheck = "";
        this.primaryName = "";
        this.primaryLabelSize = "";
        this.primaryPrinterType = "";
        this.disableSave = false;
        this.removePrimaryData();
        this.$refs.primaryForm.resetValidation();
      } else if (this.primaryCheck) {
        if (this.primaryName !== null || this.primaryName !== undefined) {
          if (this.primaryLabelSize !== null || this.primaryLabelSize !== undefined) {
            this.disableSave = false;
          }
        }
      }
    },
    //If Secondary checkbox is checked
    isSecondary() {
      if (!this.secondaryCheck) {
        this.secondaryCheck = "";
        this.secondaryName = "";
        this.secondaryLabelSize = "";
        this.secondaryPrinterType = "";
        this.disableSave = false;
        this.removeSecondaryData();
        this.$refs.secondaryForm.resetValidation();
      } else if (this.secondaryCheck) {
        if (this.secondaryName !== null || this.secondaryName !== undefined) {
          if (this.secondaryLabelSize !== null || this.secondaryLabelSize !== undefined) {
            this.disableSave = false;
          }
        }
      }
    },
    //If Default checkbox is checked
    isDefault() {
      if (!this.defaultCheck) {
        this.defaultCheck = "";
        this.defaultName = "";
        this.disableSave = false;
        this.removeDefaultData();
        this.$refs.defaultForm.resetValidation();
      } else if (this.defaultCheck) {
        if (this.defaultName !== null || this.defaultName !== undefined) {
          this.disableSave = false;
        }
      }
    },
    async saveData() {
      if (this.primaryCheck || this.secondaryCheck || this.defaultCheck) {
        if (this.primaryCheck && this.$refs.primaryForm.validate() && !this.secondaryCheck && !this.defaultCheck) {
          this.removePrimaryData();
          EncryptUtility.localStorageEncrypt("primaryCheck", true.toString());
          EncryptUtility.localStorageEncrypt("primaryName", this.primaryName.toString());
          EncryptUtility.localStorageEncrypt("primaryLabelSize", this.primaryLabelSize.toString());
          EncryptUtility.localStorageEncrypt("primaryPrinterType", this.primaryPrinterType.toString());
          let printerObj = {
            json: JSON.stringify({
              user_id: parseInt(this.userId),
              pass: 2,
              printer_id:
                EncryptUtility.localStorageDecrypt("printerID") !== undefined ? parseInt(EncryptUtility.localStorageDecrypt("printerID")) : 0,
              p_name: this.primaryName,
              p_size: this.primaryLabelSize,
              p_type: this.primaryPrinterType,
              s_name: "",
              s_size: "",
              s_type: "",
              ip: EncryptUtility.localStorageDecrypt("printerIP"),
              default_printer: "",
            }),
          };
          let showMessage = false;
          let printerData = await commonAPIService.userPrinterPreferences("post", printerObj, showMessage);
          if (printerData.message !== "NA") {
            EncryptUtility.localStorageRemoveItem("printerID");
            EncryptUtility.localStorageEncrypt("printerID", printerData?.printer_id?.toString());
            this.disableSave = true;
            let Alert = {
              type: "success",
              isShow: true,
              message: "Receiving/Inventory Printer is successfully configured.",
            };
            this.$store.commit("ConfigModule/Alert", Alert);
          }
        }
        if (this.secondaryCheck && this.$refs.secondaryForm.validate() && !this.primaryCheck && !this.defaultCheck) {
          this.removeSecondaryData();
          EncryptUtility.localStorageEncrypt("secondaryCheck", true.toString());
          EncryptUtility.localStorageEncrypt("secondaryName", this.secondaryName.toString());
          EncryptUtility.localStorageEncrypt("secondaryLabelSize", this.secondaryLabelSize.toString());
          EncryptUtility.localStorageEncrypt("secondaryPrinterType", this.secondaryPrinterType.toString());
          let printerObj = {
            json: JSON.stringify({
              user_id: parseInt(this.userId),
              pass: 2,
              printer_id:
                EncryptUtility.localStorageDecrypt("printerID") !== undefined ? parseInt(EncryptUtility.localStorageDecrypt("printerID")) : 0,
              p_name: "",
              p_size: "",
              p_type: "",
              s_name: this.secondaryName,
              s_size: this.secondaryLabelSize,
              s_type: this.secondaryPrinterType,
              ip: EncryptUtility.localStorageDecrypt("printerIP"),
              default_printer: "",
            }),
          };
          let showMessage = false;
          let printerData = await commonAPIService.userPrinterPreferences("post", printerObj, showMessage);
          if (printerData.message !== "NA") {
            EncryptUtility.localStorageRemoveItem("printerID");
            EncryptUtility.localStorageEncrypt("printerID", printerData?.printer_id?.toString());
            this.disableSave = true;
            let Alert = {
              type: "success",
              isShow: true,
              message: "Shipping Printer is successfully configured.",
            };
            this.$store.commit("ConfigModule/Alert", Alert);
          }
        }
        if (this.defaultCheck && this.$refs.defaultForm.validate() && !this.primaryCheck && !this.secondaryCheck) {
          this.removeDefaultData();
          EncryptUtility.localStorageEncrypt("defaultCheck", true.toString());
          EncryptUtility.localStorageEncrypt("defaultName", this.defaultName.toString());
          let printerObj = {
            json: JSON.stringify({
              user_id: parseInt(this.userId),
              pass: 2,
              printer_id:
                EncryptUtility.localStorageDecrypt("printerID") !== undefined ? parseInt(EncryptUtility.localStorageDecrypt("printerID")) : 0,
              p_name: "",
              p_size: "",
              p_type: "",
              s_name: "",
              s_size: "",
              s_type: "",
              ip: EncryptUtility.localStorageDecrypt("printerIP"),
              default_printer: this.defaultName,
            }),
          };
          let showMessage = false;
          let printerData = await commonAPIService.userPrinterPreferences("post", printerObj, showMessage);
          if (printerData.message !== "NA") {
            EncryptUtility.localStorageRemoveItem("printerID");
            EncryptUtility.localStorageEncrypt("printerID", printerData?.printer_id?.toString());
            this.disableSave = true;
            let Alert = {
              type: "success",
              isShow: true,
              message: "Default Printer is successfully configured.",
            };
            this.$store.commit("ConfigModule/Alert", Alert);
          }
        }
        if (this.primaryCheck && this.secondaryCheck && !this.defaultCheck) {
          if (this.$refs.primaryForm.validate() && this.$refs.secondaryForm.validate()) {
            this.removePrimaryData();
            EncryptUtility.localStorageEncrypt("primaryCheck", true.toString());
            EncryptUtility.localStorageEncrypt("primaryName", this.primaryName.toString());
            EncryptUtility.localStorageEncrypt("primaryLabelSize", this.primaryLabelSize.toString());
            EncryptUtility.localStorageEncrypt("primaryPrinterType", this.primaryPrinterType.toString());
            this.removeSecondaryData();
            EncryptUtility.localStorageEncrypt("secondaryCheck", true.toString());
            EncryptUtility.localStorageEncrypt("secondaryName", this.secondaryName.toString());
            EncryptUtility.localStorageEncrypt("secondaryLabelSize", this.secondaryLabelSize.toString());
            EncryptUtility.localStorageEncrypt("secondaryPrinterType", this.secondaryPrinterType.toString());
            let printerObj = {
              json: JSON.stringify({
                user_id: parseInt(this.userId),
                pass: 2,
                printer_id:
                  EncryptUtility.localStorageDecrypt("printerID") !== undefined ? parseInt(EncryptUtility.localStorageDecrypt("printerID")) : 0,
                p_name: this.primaryName,
                p_size: this.primaryLabelSize,
                p_type: this.primaryPrinterType,
                s_name: this.secondaryName,
                s_size: this.secondaryLabelSize,
                s_type: this.secondaryPrinterType,
                ip: EncryptUtility.localStorageDecrypt("printerIP"),
                default_printer: "",
              }),
            };
            let showMessage = false;
            let printerData = await commonAPIService.userPrinterPreferences("post", printerObj, showMessage);
            if (printerData.message !== "NA") {
              EncryptUtility.localStorageRemoveItem("printerID");
              EncryptUtility.localStorageEncrypt("printerID", printerData?.printer_id?.toString());
              this.disableSave = true;
              let Alert = {
                type: "success",
                isShow: true,
                message: "Printers are successfully configured.",
              };
              this.$store.commit("ConfigModule/Alert", Alert);
            }
          }
        }
        if (this.primaryCheck && !this.secondaryCheck && this.defaultCheck) {
          if (this.$refs.primaryForm.validate() && this.$refs.defaultForm.validate()) {
            this.removePrimaryData();
            EncryptUtility.localStorageEncrypt("primaryCheck", true.toString());
            EncryptUtility.localStorageEncrypt("primaryName", this.primaryName.toString());
            EncryptUtility.localStorageEncrypt("primaryLabelSize", this.primaryLabelSize.toString());
            EncryptUtility.localStorageEncrypt("primaryPrinterType", this.primaryPrinterType.toString());
            this.removeDefaultData();
            EncryptUtility.localStorageEncrypt("defaultCheck", true.toString());
            EncryptUtility.localStorageEncrypt("defaultName", this.defaultName.toString());
            let printerObj = {
              json: JSON.stringify({
                user_id: parseInt(this.userId),
                pass: 2,
                printer_id:
                  EncryptUtility.localStorageDecrypt("printerID") !== undefined ? parseInt(EncryptUtility.localStorageDecrypt("printerID")) : 0,
                p_name: this.primaryName,
                p_size: this.primaryLabelSize,
                p_type: this.primaryPrinterType,
                s_name: "",
                s_size: "",
                s_type: "",
                ip: EncryptUtility.localStorageDecrypt("printerIP"),
                default_printer: this.defaultName,
              }),
            };
            let showMessage = false;
            let printerData = await commonAPIService.userPrinterPreferences("post", printerObj, showMessage);
            if (printerData.message !== "NA") {
              EncryptUtility.localStorageRemoveItem("printerID");
              EncryptUtility.localStorageEncrypt("printerID", printerData?.printer_id?.toString());
              this.disableSave = true;
              let Alert = {
                type: "success",
                isShow: true,
                message: "Printers are successfully configured.",
              };
              this.$store.commit("ConfigModule/Alert", Alert);
            }
          }
        }
        if (!this.primaryCheck && this.secondaryCheck && this.defaultCheck) {
          if (this.$refs.secondaryForm.validate() && this.$refs.defaultForm.validate()) {
            this.removeSecondaryData();
            EncryptUtility.localStorageEncrypt("secondaryCheck", true.toString());
            EncryptUtility.localStorageEncrypt("secondaryName", this.secondaryName.toString());
            EncryptUtility.localStorageEncrypt("secondaryLabelSize", this.secondaryLabelSize.toString());
            EncryptUtility.localStorageEncrypt("secondaryPrinterType", this.secondaryPrinterType.toString());
            this.removeDefaultData();
            EncryptUtility.localStorageEncrypt("defaultCheck", true.toString());
            EncryptUtility.localStorageEncrypt("defaultName", this.defaultName.toString());
            let printerObj = {
              json: JSON.stringify({
                user_id: parseInt(this.userId),
                pass: 2,
                printer_id:
                  EncryptUtility.localStorageDecrypt("printerID") !== undefined ? parseInt(EncryptUtility.localStorageDecrypt("printerID")) : 0,
                p_name: "",
                p_size: "",
                p_type: "",
                s_name: this.secondaryName,
                s_size: this.secondaryLabelSize,
                s_type: this.secondaryPrinterType,
                ip: EncryptUtility.localStorageDecrypt("printerIP"),
                default_printer: this.defaultName,
              }),
            };
            let showMessage = false;
            let printerData = await commonAPIService.userPrinterPreferences("post", printerObj, showMessage);
            if (printerData.message !== "NA") {
              EncryptUtility.localStorageRemoveItem("printerID");
              EncryptUtility.localStorageEncrypt("printerID", printerData?.printer_id?.toString());
              this.disableSave = true;
              let Alert = {
                type: "success",
                isShow: true,
                message: "Printers are successfully configured.",
              };
              this.$store.commit("ConfigModule/Alert", Alert);
            }
          }
        }
        if (this.primaryCheck && this.secondaryCheck && this.defaultCheck) {
          if (this.$refs.primaryForm.validate() && this.$refs.secondaryForm.validate() && this.$refs.defaultForm.validate()) {
            this.removePrimaryData();
            EncryptUtility.localStorageEncrypt("primaryCheck", true.toString());
            EncryptUtility.localStorageEncrypt("primaryName", this.primaryName.toString());
            EncryptUtility.localStorageEncrypt("primaryLabelSize", this.primaryLabelSize.toString());
            EncryptUtility.localStorageEncrypt("primaryPrinterType", this.primaryPrinterType.toString());
            this.removeSecondaryData();
            EncryptUtility.localStorageEncrypt("secondaryCheck", true.toString());
            EncryptUtility.localStorageEncrypt("secondaryName", this.secondaryName.toString());
            EncryptUtility.localStorageEncrypt("secondaryLabelSize", this.secondaryLabelSize.toString());
            EncryptUtility.localStorageEncrypt("secondaryPrinterType", this.secondaryPrinterType.toString());
            this.removeDefaultData();
            EncryptUtility.localStorageEncrypt("defaultCheck", true.toString());
            EncryptUtility.localStorageEncrypt("defaultName", this.defaultName.toString());
            let printerObj = {
              json: JSON.stringify({
                user_id: parseInt(this.userId),
                pass: 2,
                printer_id:
                  EncryptUtility.localStorageDecrypt("printerID") !== undefined ? parseInt(EncryptUtility.localStorageDecrypt("printerID")) : 0,
                p_name: this.primaryName,
                p_size: this.primaryLabelSize,
                p_type: this.primaryPrinterType,
                s_name: this.secondaryName,
                s_size: this.secondaryLabelSize,
                s_type: this.secondaryPrinterType,
                ip: EncryptUtility.localStorageDecrypt("printerIP"),
                default_printer: this.defaultName,
              }),
            };
            let showMessage = false;
            let printerData = await commonAPIService.userPrinterPreferences("post", printerObj, showMessage);
            if (printerData.message !== "NA") {
              EncryptUtility.localStorageRemoveItem("printerID");
              EncryptUtility.localStorageEncrypt("printerID", printerData?.printer_id?.toString());
              this.disableSave = true;
              let Alert = {
                type: "success",
                isShow: true,
                message: "Printers are successfully configured.",
              };
              this.$store.commit("ConfigModule/Alert", Alert);
            }
          }
        }
        if (!this.primaryCheck) {
          this.removePrimaryData();
        }
        if (!this.secondaryCheck) {
          this.removeSecondaryData();
        }
        if (!this.defaultCheck) {
          this.removeDefaultData();
        }
      } else if (!this.primaryCheck && !this.secondaryCheck && !this.defaultCheck) {
        let Alert = {
          type: "success",
          isShow: true,
          message: "Printer configuration is successfully cleared.",
        };
        this.$store.commit("ConfigModule/Alert", Alert);
        this.disableSave = true;
      }
    },
    //Removing the cookie data
    async removeData() {
      let printerObj = {
        json: JSON.stringify({
          user_id: parseInt(this.userId),
          pass: 3,
          printer_id: parseInt(EncryptUtility.localStorageDecrypt("printerID")),
          p_name: this.primaryName,
          p_size: this.primaryLabelSize,
          p_type: this.primaryPrinterType,
          s_name: this.secondaryName,
          s_size: this.secondaryLabelSize,
          s_type: this.secondaryPrinterType,
          ip: EncryptUtility.localStorageDecrypt("printerIP"),
          default_printer: this.defaultName,
        }),
      };
      let showMessage = true;
      let printerData = await commonAPIService.userPrinterPreferences("post", printerObj, showMessage);
      if (printerData.message !== "NA") {
        this.removePrimaryData();
        this.removeSecondaryData();
        this.removeDefaultData();
        this.resetFunction();
        let Alert = {
          type: "success",
          isShow: true,
          message: "Printer configuration is successfully cleared.",
        };
        this.$store.commit("ConfigModule/Alert", Alert);
      }
    },
    //Remove primary printer details
    removePrimaryData() {
      EncryptUtility.localStorageRemoveItem("primaryCheck");
      EncryptUtility.localStorageRemoveItem("primaryName");
      EncryptUtility.localStorageRemoveItem("primaryLabelSize");
      EncryptUtility.localStorageRemoveItem("primaryPrinterType");
    },
    //Remove secondary printer details
    removeSecondaryData() {
      EncryptUtility.localStorageRemoveItem("secondaryCheck");
      EncryptUtility.localStorageRemoveItem("secondaryName");
      EncryptUtility.localStorageRemoveItem("secondaryLabelSize");
      EncryptUtility.localStorageRemoveItem("secondaryPrinterType");
    },
    //Remove default printer details
    removeDefaultData() {
      EncryptUtility.localStorageRemoveItem("defaultCheck");
      EncryptUtility.localStorageRemoveItem("defaultName");
    },
    //Check same printers are being used or not
    checkPrinter(printers) {
      if (this.primaryCheck) {
        let priPrinter = printers.find((element) => element == this.primaryName);
        if (priPrinter == undefined && priPrinter == null) {
          let Alert = {
            type: "error",
            isShow: true,
            message: "Receiving/Inventory Printer is not configured.",
          };
          this.$store.commit("ConfigModule/Alert", Alert);
        }
      } else if (this.secondaryCheck) {
        let secPrinter = printers.find((element) => element == this.secondaryName);
        if (secPrinter == undefined && secPrinter == null) {
          let Alert = {
            type: "error",
            isShow: true,
            message: "Shipping Printer is not configured.",
          };
          this.$store.commit("ConfigModule/Alert", Alert);
        }
      } else if (this.defaultCheck) {
        let defPrinter = printers.find((element) => element == this.defaultName);
        if (defPrinter == undefined && defPrinter == null) {
          let Alert = {
            type: "error",
            isShow: true,
            message: "Default Printer is not configured.",
          };
          this.$store.commit("ConfigModule/Alert", Alert);
        }
      }
    },
    //Checking whether printer software is present in the system or not
    onInit() {
      var _this = this;
      JSPM.JSPrintManager.auto_reconnect = true;
      JSPM.JSPrintManager.start();
      if (JSPM.JSPrintManager.websocket_status == JSPM.WSStatus.Open) {
        _this.getPrinters().then((p) => {
          _this.printers = p;
          _this.checkPrinter(_this.printers);
          _this.$nextTick();
        });
      }
      var index = 0;
      JSPM.JSPrintManager.WS.onStatusChanged = function () {
        if (JSPM.JSPrintManager.websocket_status == JSPM.WSStatus.Open) {
          _this.getPrinters().then((p) => {
            _this.printers = p;
            _this.checkPrinter(_this.printers);
            _this.$nextTick();
          });
        } else {
          if (JSPM.JSPrintManager.websocket_status != JSPM.WSStatus.WaitingForUserResponse && index == 0) {
            console.warn("JSPM WS not open");
            alert(
              "Printer utility is not installed, please download the Printer utility from page menu and install. If it has already been downloaded, please open it. It is not running in the background."
            );
            index++;
          }
        }
      };
    },
    //Downloading the Printer software
    downloadUtility() {
      let LoaderDialog = {
        visible: true,
        title: "Please Wait...",
      };
      this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
      this.axios
        .get("co/cc_file_url?file_path=common/jspm7_0_2024.zip")
        .then((response) => {
          if (response.status == 200 || response.status == 204) {
            LoaderDialog.visible = false;
            this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
            var popup_window = window.open(response.data.body, "_blank");
            try {
              popup_window.focus();
            } catch (e) {
              let Alert = {
                type: "error",
                isShow: true,
                message: "Pop-up Blocker is enabled! Please add this site to your exception list.",
              };
              this.$store.commit("ConfigModule/Alert", Alert);
            }
          } else {
            LoaderDialog.visible = false;
            this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
            let Alert = {
              type: "error",
              isShow: true,
              message: response.data.message,
            };
            this.$store.commit("ConfigModule/Alert", Alert);
          }
          LoaderDialog.visible = false;
          this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
        })
        .catch((error) => {
          LoaderDialog.visible = false;
          this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
          let Alert = {
            type: "error",
            isShow: true,
            message: Utility.apiError(error),
          };
          this.$store.commit("ConfigModule/Alert", Alert);
          this.errorMessage = error.message;
        });
      LoaderDialog.visible = false;
      this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
    },
    //Getting all the printers which are connected to the user device
    getPrinters() {
      return new Promise((ok, err) => {
        let printers = [];
        if (JSPM.JSPrintManager.websocket_status == JSPM.WSStatus.Open) {
          JSPM.JSPrintManager.getPrinters()
            .then(function (myPrinters) {
              printers = myPrinters;
              ok(printers);
            })
            .catch((e) => err(e));
        } else {
          alert(
            "Printer utility ---> Printer utility is not installed, please download the Printer utility from page menu and install. If it has already been downloaded, please open it. It is not running in the background."
          );
          ok(printers);
        }
      });
    },
  },
  components: {
    breadcrumbComp,
  },
};
